exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-educacao-js": () => import("./../../../src/pages/educacao.js" /* webpackChunkName: "component---src-pages-educacao-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obcrei-js": () => import("./../../../src/pages/obcrei.js" /* webpackChunkName: "component---src-pages-obcrei-js" */),
  "component---src-pages-pessoas-js": () => import("./../../../src/pages/pessoas.js" /* webpackChunkName: "component---src-pages-pessoas-js" */),
  "component---src-pages-projeto-js": () => import("./../../../src/pages/projeto.js" /* webpackChunkName: "component---src-pages-projeto-js" */),
  "component---src-pages-projetos-js": () => import("./../../../src/pages/projetos.js" /* webpackChunkName: "component---src-pages-projetos-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-sobre-obcrei-js": () => import("./../../../src/pages/sobre-obcrei.js" /* webpackChunkName: "component---src-pages-sobre-obcrei-js" */)
}

